import Buscador from '../Buscador/Buscador';
import Logo from '../Logo/Logo';
import './Header.css'

export default function Navbar() {
    return (
        <nav className="navbar navbar-dark shadow fixed-top">
            <div className="container-fluid">
                <div className="d-flex">
                    <button className="navbar-toggler sombra" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu" title="Mostrar men�">
                    <span className="navbar-toggler-icon"></span>
                </button>
                    <Logo />
                </div>
                <div className="d-none d-lg-block w-25">
                    <Buscador id="buscador-header" />
                </div>                
            </div>
        </nav>
    )
}