import './Destacados.css'
import 'bootstrap/js/dist/carousel';
import { useEffect, useState } from 'react';
import LanzamientoDestacado from '../../types/LanzamientoDestacadoType';
import Carousel from 'bootstrap/js/dist/carousel';
import { Link } from 'react-router-dom';
import { plataformas } from '../../constants/plataformas';
import Plataforma from '../Plataforma/Plataforma';
import apiService from '../../services/ApiService';
import dateService from '../../services/DateService';

export default function Destacados() {
    const [lanzamientosDestacados, setLanzamientosDestacados] = useState<LanzamientoDestacado[]>([]);

    useEffect(() => {
        apiService.obtenerLanzamientosDestacados()
            .then(function (response) {
                setLanzamientosDestacados(response.data);
                const carousel = new Carousel("#carousel-container");
                carousel.cycle();
            })
    }, []);

    const esActive = function (lanzamientosDestacados: LanzamientoDestacado[], lanzamientoDestacado: LanzamientoDestacado) {
        return lanzamientosDestacados[0] === lanzamientoDestacado;
    }

    const placeholder =
        <div className="placeholder">
            <img className="d-block w-100 placeholder-wave rounded" src="/placeholder_720.jpg" />
        </div>;

    const carouselItems = lanzamientosDestacados.map(lanzamientoDestacado =>
        <Link to={`/juegos/${lanzamientoDestacado.idJuego}/${lanzamientoDestacado.slug}`}
            key={`${lanzamientoDestacado.idJuego}-${lanzamientoDestacado.fechaLanzamiento}`}
        >
            <div className={`carousel carousel-item ${esActive(lanzamientosDestacados, lanzamientoDestacado) ? 'active' : ''}`}>            
                <img src={lanzamientoDestacado.urlArtwork} className="d-block w-100" alt={lanzamientoDestacado.titulo} />
                <div className="carousel-caption d-md-block">
                    <h5 className="fw-semibold">{lanzamientoDestacado.titulo}</h5>
                    <div className="d-flex flex-row justify-content-center">
                        {lanzamientoDestacado.idsPlataformas
                            .sort((a, b) => plataformas[a - 1].nombre.toLowerCase().localeCompare(plataformas[b - 1].nombre.toLowerCase()))
                            .map(idPlataforma =>
                                <div className="text-center plataforma-lista pe-1" key={idPlataforma}>
                                    <Plataforma
                                        idPlataforma={idPlataforma}
                                        justifyContent='justify-content-center'
                                        deshabilitado={false} />
                                </div>
                            )}
                    </div>
                    <p>{dateService.formatearFechaLanzamiento(lanzamientoDestacado.fechaLanzamiento)}</p>
                </div>
            </div>
        </Link>
    )

    const carouselIndicators = lanzamientosDestacados.map(lanzamientoDestacado =>
        <button
            key={`${lanzamientoDestacado.idJuego}-${lanzamientoDestacado.fechaLanzamiento}`}
            type="button"
            data-bs-target="#carousel-container"
            data-bs-slide-to={lanzamientosDestacados.indexOf(lanzamientoDestacado)}
            className={esActive(lanzamientosDestacados, lanzamientoDestacado) ? "active" : ""}
            aria-current={esActive(lanzamientosDestacados, lanzamientoDestacado) ? "true" : "false"}
            aria-label={lanzamientoDestacado.titulo}>
        </button>
    )

    return (
        <div className="">            
            {lanzamientosDestacados.length === 0 && placeholder}
            <div id="carousel-container"
                className="carousel slide carousel-fade align-self-center sombra"
                data-bs-ride="carousel"
                data-interval="2000"                
            >
                <div className="carousel-indicators">
                    {carouselIndicators}
                </div>
                <div className="carousel-inner">
                    {carouselItems}
                </div>                
            </div>
        </div>        
    )
}