import "./ListaLanzamientosTarjetas.css"
import ApiService, { ObtenerLanzamientosParams } from "../../services/ApiService";
import TarjetaLanzamiento from "../TarjetaLanzamiento/TarjetaLanzamiento";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import PaginacionLanzamientos from "../Paginacion/PaginacionLanzamientos";
import { descripcionLanzamientosRecientes, lanzamientosRecientes, perfectoNovedades, todoLanzamientos } from "../../constants/textos";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { plataformasOrdenadas } from "../../constants/plataformas";
import ResultadoObtenerLanzamientosType from "../../types/ResultadoObtenerLanzamientosType";

export default function ListaLanzamientosRecientesTarjetas() {    
    const [pagina, setPagina] = useState(0);
    const paginaParam = useParams<ObtenerLanzamientosParams>().pagina ?? "1";
    const navigate = useNavigate();

     useEffect(() => {
        if (paginaParam) {
            const numberPagina = Number(paginaParam);
            if (Number.isInteger(numberPagina)) {
                setPagina(numberPagina);
            }
            if (!Number.isInteger(numberPagina) || numberPagina < 1) {
                navigate("/error", { replace: true });
            }
        }
    }, [paginaParam, pagina, navigate]);

    const {
        data,        
        isLoading
      } = useQuery({
          queryKey: [`obtenerLanzamientosRecientes`, pagina],
          queryFn: async () => {
              if (pagina > 0) {
                  const res = await ApiService.obtenerLanzamientosRecientes(pagina, false);
                  return res.data    
              }
              return {} as ResultadoObtenerLanzamientosType;
        },        
      });    

    const placeholder = [...Array(24)].map((e, i) => <TarjetaLanzamiento
        titulo=""
        urlImagen="/placeholder_tarjeta.jpg"
        idJuego={0}
        fechaLanzamiento={new Date()}
        mostrarPlataformas={true}
        idsPlataformas={[]}
        slug=""
        placeholder={true}
        key={`lanzamiento_placeholder_${i}`}
    />);    

    function obtenerDescripcion() {
        const formateador = new Intl.ListFormat('es', { style: 'long', type: 'conjunction' });
        const nombresPlataformas = plataformasOrdenadas.map(x => x.nombre);

        return `${descripcionLanzamientosRecientes} ${formateador.format(nombresPlataformas)}. ${perfectoNovedades}`
    }

    return (      
        <div className="my-2">       
            <Helmet prioritizeSeoTags>
                <title>{`${lanzamientosRecientes} - ${todoLanzamientos}`}</title>
                <meta name="description" content={obtenerDescripcion()} />
                <meta property="og:title" content={`${lanzamientosRecientes} - ${todoLanzamientos}`} />
                <meta property="og:description" content={obtenerDescripcion()} />
            </Helmet>
            <h1 className="titulo-pantalla-lanzamientos mb-4 fw-semibold">{lanzamientosRecientes}</h1>
            
            <PaginacionLanzamientos
                pagina={pagina}
                resultadoObtenerLanzamientos={data}
                prefijoRuta="lanzamientos-recientes"
                key="paginacion_top"
            />            
                
            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6">    
                {isLoading && placeholder}
                {
                    data?.lanzamientos?.map((lanzamiento) => (
                            <TarjetaLanzamiento key={`${lanzamiento.idJuego}-${lanzamiento.fechaLanzamiento}`}
                            idJuego={lanzamiento.idJuego}
                            titulo={lanzamiento.titulo}
                            urlImagen={lanzamiento.urlImagen}
                            fechaLanzamiento={lanzamiento.fechaLanzamiento}
                            idsPlataformas={lanzamiento.idsPlataformas}
                            slug={lanzamiento.slug}
                            mostrarPlataformas={true}
                        />
                    ))
                }                   
            </div>            

            <PaginacionLanzamientos
                pagina={pagina}
                resultadoObtenerLanzamientos={data}
                prefijoRuta="lanzamientos-recientes"
                key="paginacion_bottom"
            />        
            
        </div>
    )
}