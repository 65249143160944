import { CSSProperties } from "react"
import { Link } from "react-router-dom"
import { plataformas } from "../../constants/plataformas"
import { sinPortadaMiniatura } from "../../constants/sinPortada"
import dateService from "../../services/DateService"
import Plataforma from "../Plataforma/Plataforma"
import "./ElementoListaLanzamientoVertical.css"

export default function ElementoListaLanzamientoVertical(props: ElementoListaLanzamientoVerticalProps) {
    const urlImagen = props.urlImagen?.length ? props.urlImagen : sinPortadaMiniatura;
    const classDivListaVertical = props.placeholder ? "list-group-item elemento-lista-lanzamiento-vertical placeholder-wave" : "list-group-item elemento-lista-lanzamiento-vertical";
    const classDiv = props.placeholder ? "d-flex placeholder bg-dark" : "d-flex";
    const styleLink: CSSProperties | undefined = props.placeholder ? { pointerEvents: "none" } : undefined;
    const classTitulo = props.fechaLanzamiento ?
        'col-7 col-xlg-9 align-self-center d-flex justify-content-start ps-1' :
        'col-10 col-xlg-11 align-self-center d-flex justify-content-start ps-1';

    return (        
        <div className={classDivListaVertical} title={props.titulo}>
            <Link to={`/juegos/${props.idJuego}/${props.slug}`} style={styleLink} >
                <div className={classDiv}>
                    <img className="align-self-center col-2 col-lg-1" src={urlImagen} alt={props.titulo} />
                    <div className={classTitulo}>
                        <div className="text-truncate titulo-lista fw-semibold text-start">
                            <span className="align-self-start">{props.titulo}</span>
                            {props.idsPlataformas &&
                                <div className="d-flex flex-row justify-content-start">
                                    {props.idsPlataformas
                                        .sort((a, b) => plataformas[a - 1].nombre.toLowerCase().localeCompare(plataformas[b - 1].nombre.toLowerCase()))
                                        .map(idPlataforma =>
                                            <div className="text-center plataforma-lista pe-1" key={idPlataforma}>
                                                <Plataforma
                                                    idPlataforma={idPlataforma}
                                                    justifyContent='justify-content-center'
                                                    deshabilitado={false} />
                                            </div>
                                        )}
                                </div>
                            }  
                        </div>                        
                    </div>
                    {props.fechaLanzamiento &&
                        <div className="col-3 col-xlg-2 align-self-center ms-auto fecha-lanzamiento">
                            <span>{!props.placeholder && dateService.formatearFechaLanzamiento(props.fechaLanzamiento)}</span>
                        </div>
                    }
                </div>
            </Link>
        </div>
    )
}

export interface ElementoListaLanzamientoVerticalProps {
    idJuego: number,
    titulo: string,
    urlImagen: string,
    fechaLanzamiento?: Date,
    idsPlataformas?: number[],
    slug: string,
    placeholder?: boolean
}
