import "./EasterEgg.css"
import Konami from 'react-konami-code';

export default function EasterEgg() {
    function easterEgg() {

    }

    return (
        <Konami action={easterEgg} timeout={6000}>
            <div className="easter-egg">
                <img className="img-fluid" src="/confundido.png" alt="Easter egg" />
            </div>
        </Konami>
    )
}