import { Link } from "react-router-dom"
import { acercaDe, politicaCookies, politicaPrivacidad, siguenosEn, todoLanzamientos } from "../../constants/textos"
import "./Footer.css"

export default function Footer() {
    return (
        <footer className="py-3 my-4" >
            <ul className="nav justify-content-center border-bottom pb-3 mb-3">
                <li className="nav-item"><Link to="/acerca-de" className="nav-link px-2 text-body-secondary">{acercaDe}</Link></li>
                <li className="nav-item"><Link to="/politica-privacidad" className="nav-link px-2 text-body-secondary">{politicaPrivacidad}</Link></li>
                <li className="nav-item"><Link to="/politica-cookies" className="nav-link px-2 text-body-secondary">{politicaCookies}</Link></li>
            </ul>

            <div className="redes-sociales">
                <p className="text-center text-body-secondary">{siguenosEn}</p>
                <ul className="nav justify-content-center mb-2 redes-sociales">                
                    <li className="nav-item"><a href="https://twitter.com/TdoLanzamientos" className="nav-link px-2 text-body-secondary"><img src="/icons/twitter.png" alt="Twitter" title="Twitter"/></a></li>
                    <li className="nav-item"><a href="https://www.instagram.com/todolanzamientos/" className="nav-link px-2 text-body-secondary"><img src="/icons/instagram.png" alt="Instagram" title="Instagram" /></a></li>
                    <li className="nav-item"><a href="https://t.me/TodoLanzamientos" className="nav-link px-2 text-body-secondary"><img src="/icons/telegram.png" alt="Telegram" title="Telegram" /></a></li>
                    </ul>
                </div>
            <p className="text-center text-body-secondary">{todoLanzamientos}</p>
      </footer >
    )
}