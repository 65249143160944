import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route, Navigate } from 'react-router-dom';
import ListaLanzamientosTarjetas from './components/ListaLanzamientosTarjetas/ListaLanzamientosTarjetas';
import DetalleJuego from './components/DetalleJuego/DetalleJuego';
import PaginaInicial from './components/PaginaInicial/PaginaInicial';
import ListaLanzamientosRecientesTarjetas from './components/ListaLanzamientosTarjetas/ListaLanzamientosRecientesTarjetas';
import BusquedaAvanzada from './components/BusquedaAvanzada/BusquedaAvanzada';
import Error404 from './components/Error404/Error404';
import PoliticaPrivacidad from './components/PoliticaPrivacidad/PoliticaPrivacidad';
import AcercaDe from './components/AcercaDe/AcercaDe';
import PoliticaCookies from './components/PoliticaCookies/PoliticaCookies';
import { HelmetProvider } from 'react-helmet-async';
import DetalleArticulo from './components/DetalleArticulo/DetalleArticulo';
import ListaArticulos from './components/ListaArticulos/ListaArticulos';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route path="" element={<PaginaInicial />} />
            <Route path="lanzamientos/plataforma/:idPlataforma/:slug?/pagina/:pagina" element={<ListaLanzamientosTarjetas />} />
            <Route path="lanzamientos/pagina/:pagina" element={<ListaLanzamientosTarjetas />} />
            <Route path="lanzamientos-recientes/pagina/:pagina" element={<ListaLanzamientosRecientesTarjetas />} />
            <Route path="juegos/:idJuego/:slug?" element={<DetalleJuego />} />
            <Route path="buscar" element={<BusquedaAvanzada />} />
            <Route path="politica-privacidad" element={<PoliticaPrivacidad />} />
            <Route path="politica-cookies" element={<PoliticaCookies />} />
            <Route path="acerca-de" element={<AcercaDe />} />
            <Route path="articulos/pagina/:pagina" element={<ListaArticulos />} />
            <Route path="articulo/:idArticulo/:slug?" element={<DetalleArticulo />} />
            <Route path="error" element={<Error404 />} />
            <Route path="*" element={<Navigate replace to="/error" />} />
        </Route>
    )
);

root.render(
    <React.StrictMode>
        <HelmetProvider>
            <RouterProvider router={router} />
        </HelmetProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();