import { Link } from "react-router-dom"
import "./Logo.css"

export default function Logo() {
    return (
        <Link className="navbar-brand" to="/">
            <div className="logo">
                <img src="/icon.png" alt="Todo Lanzamientos" />
                <span className="color-a">Todo</span><span className="color-b">Lanzamientos</span>
            </div>
        </Link>
    )
}