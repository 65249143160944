import Destacados from "../Destacados/Destacados"
import ListaLanzamientosVertical from "../ListaLanzamientosVertical/ListaLanzamientosVertical"
import ListaPlataformas from "../ListaPlataformas/ListaPlataformas"
import "./PaginaInicial.css"

export default function PaginaInicial() {
    return (
        <div>
            <div className="row justify-content-md-center my-2">
                <div className="col-lg-8"><Destacados /></div>
                <div className="col-lg-4 d-none d-lg-block"><ListaPlataformas /></div>
            </div>
            <ListaLanzamientosVertical />
        </div>
    )
}