import http from "../http-common";
import DetalleArticuloType from "../types/DetalleArticuloType";
import DetalleJuegoType from "../types/DetalleJuegoType";
import LanzamientoDestacadoType from "../types/LanzamientoDestacadoType";
import ResultadoBusquedaJuegoType from "../types/ResultadoBusquedaJuegoType";
import ResultadoObtenerArticulosType from "../types/ResultadoObtenerArticulosType";
import ResultadoObtenerLanzamientos from "../types/ResultadoObtenerLanzamientosType";

class ApiService {
    async obtenerLanzamientos(pagina: number, vistaListadoVertical: boolean) {
        return await http.get<ResultadoObtenerLanzamientos>(`lanzamientos?pagina=${pagina}&vistaListadoVertical=${vistaListadoVertical}`);
    }

    async obtenerLanzamientosPorPlataforma(pagina: number, idPlataforma: number) {
        return await http.get<ResultadoObtenerLanzamientos>(`lanzamientos?pagina=${pagina}&idPlataforma=${idPlataforma}`);
    }

    async buscarJuego(titulo: string) {
        const data = {
            titulo
        };

        return await http.post<Array<ResultadoBusquedaJuegoType>>(
            `juegos/buscar/`,
            data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    async buscarAvanzadoJuego(titulo: string, idsPlataformas : number[], fechaInicio : Date, fechaFin : Date) {
        const data = {
            titulo,
            idsPlataformas,
            fechaInicio,
            fechaFin
        };
        return await http.post<Array<ResultadoBusquedaJuegoType>>(
            `juegos/buscar/avanzado/`,
            data, {
                headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    async obtenerJuego(idJuego: string) {
        return await http.get<DetalleJuegoType>(`juegos/${idJuego}`);
    }

    async obtenerLanzamientosDestacados() {
        return await http.get<Array<LanzamientoDestacadoType>>(`lanzamientos/destacados`);
    }

    async obtenerLanzamientosRecientes(pagina: number, vistaListadoVertical: boolean) {
        return await http.get<ResultadoObtenerLanzamientos>(`lanzamientos/recientes?pagina=${pagina}&vistaListadoVertical=${vistaListadoVertical}`);
    }

    async obtenerArticulo(idArticulo: string) {
        return await http.get<DetalleArticuloType>(`articulos/${idArticulo}`);
    }

    async obtenerArticulosRecientes() {
        return await http.get<ResultadoObtenerArticulosType>(`articulos`);
    }

    async obtenerArticulos(pagina: number) {
        return await http.get<ResultadoObtenerArticulosType>(`articulos?pagina=${pagina}`);
    }
}

const apiService = new ApiService();
export default apiService;

export type ObtenerJuegoParams = {
    idJuego: string
}

export type ObtenerLanzamientosParams = {
    pagina : string,
    idPlataforma : string
}

export type ObtenerDetalleArticuloParams = {
    idArticulo: string
}

export type ObtenerArticulosParams = {
    pagina: string
}