export const lanzamientosRecientes = "Lanzamientos recientes";
export const proximosLanzamientos = "Próximos lanzamientos";
export const verTodos = "Ver todos";
export const todoLanzamientos = "Todo Lanzamientos";
export const busquedaAvanzada = "Búsqueda avanzada"
export const titulo = "Título";
export const plataforma = "Plataforma";
export const fechaLanzamiento = "Fecha de lanzamiento";
export const desde = "Desde";
export const hasta = "Hasta";
export const buscar = "Buscar";
export const mensajeErrorTitulo = 'Escribe un título para realizar la búsqueda';
export const resultadosLimitados24 = 'El número de resultados ha sido limitado a 24';
export const noSeHanEncontradoResultados = 'No se han encontrado resultados. ';
export const accedeABusquedaAvanzada = "Accede a la búsqueda avanzada para filtrar con más detalle."
export const inicio = "Inicio";
export const politicaPrivacidad = "Política de privacidad";
export const acercaDe = "Acerca de";
export const lanzamientoDe = "Lanzamiento de";
export const verEnTodoLanzamientos = "Ver ficha del juego en Todo Lanzamientos";
export const anadirCalendario = "Añadir al calendario"
export const avisoPosibleCambioFecha = "Las fechas de lanzamiento podrían sufrir cambios y el evento de calendario quedarse obsoleto. Ten esto en cuenta al añadirlo."
export const noSeHaEncontradoUnaDescripcionJuego = "No se ha encontrado una descripcion para el juego.";
export const error404 = "Error 404";
export const textoError404 = "La página solicitada no se ha encontrado. Quizás haya sido abducida...";
export const busquedaPorPlataforma = "Búsqueda por plataforma";
export const acercaDeParrafo1 = "Todo Lanzamientos es un sitio web que busca por ti toda la información sobre los próximos lanzamientos de videojuegos en España, para que no te pierdas ningún estreno.";
export const acercaDeParrafo2 = "¿Estás esperando ese juego al que tantas ganas tienes pero no recuerdas cuándo salía? ¿Te sobra dinero este mes y quieres ver qué novedades puedes comprar? ¿Te encanta amasar una cantidad ridícula de juegos? Todo Lanzamientos está hecho para ti.";
export const acercaDeParrafo3 = "Si no encuentras un juego, es posible que aun no tenga una fecha confirmada o que quede lejos su lanzamiento. ¡No te preocupes! Los datos se actualizan a diario y terminará apareciendo.";
export const acercaDeParrafo4 = "Si tienes cualquier duda, sugerencia de mejora o quieres reportar un error, por favor contacta a través de la dirección de correo";
export const emailParte1 = "contacto";
export const emailParte2 = "@";
export const emailParte3 = "todolanzamientos.es";
export const textoConsentimiento = "Utilizamos cookies propias y de terceros para analizar el uso del sitio web y mostrarte publicidad relacionada con tus preferencias sobre la base de un perfil elaborado a partir de tus hábitos de navegación (por ejemplo, páginas visitadas). ";
export const aceptasLasCookies = "¿Aceptas las cookies?";
export const si = "SÍ";
export const no = "NO";
export const masInformacion = "Más información";
export const politicaCookies = "Política de cookies";
export const descripcionWeb = "Encuentra toda la información sobre los próximos estrenos de videojuegos en España. ¡No te pierdas ninguna novedad de Nintendo Switch, PC, PlayStation 4, PlayStation 5, Xbox One y Xbox Series X|S!";
export const descripcionLanzamientosRecientes = "Aquí encontrarás toda la información sobre los juegos lanzados recientemente en España para";
export const descripcionProximosLanzamientos = "Entérate de toda la información sobre los próximos juegos que se estrenan en España para";
export const perfectoNovedades = "¡Perfecto para estar a la última en las novedades de videojuegos!";
export const genero = "Género";
export const siguenosEn = "Síguenos en";
export const articulos = "Artículos";
export const descripcionArticulos = "En nuestra sección de artículos encontrarás novedades, recomendaciones, ofertas, noticias e información de interés sobre videojuegos."
export const verMasArticulos = "Ver más artículos";