import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { plataformasOrdenadas } from '../../constants/plataformas';
import { buscar, busquedaAvanzada, desde, fechaLanzamiento, hasta, mensajeErrorTitulo, noSeHanEncontradoResultados, plataforma, resultadosLimitados24, titulo, todoLanzamientos } from '../../constants/textos'
import apiService from '../../services/ApiService';
import dateService from '../../services/DateService';
import ResultadoBusquedaJuegoType from '../../types/ResultadoBusquedaJuegoType';
import ElementoListaLanzamientoVertical from '../ElementoListaLanzamientoVertical/ElementoListaLanzamientoVertical';
import './BusquedaAvanzada.css'
import { Helmet } from 'react-helmet-async';

export default function BusquedaAvanzada() {
    const [resultados, setResultados] = useState<Array<ResultadoBusquedaJuegoType>>();
    const [tituloJuego, setTituloJuego] = useState("");
    const [errorTitulo, setErrorTitulo] = useState(false);
    const [idsPlataformas, setIdsPlataformas] = useState<number[]>(plataformasOrdenadas.map(x => x.idPlataforma));
    const [fechaDesde, setFechaDesde] = useState<Date>(new Date());
    const [fechaHasta, setFechaHasta] = useState<Date>(dayjs(new Date()).add(6, 'month').toDate());
    const [buscarDesdeUrl, setBuscarDesdeUrl] = useState(false);
    const [cargando, setCargando] = useState(false);
    const divTitulo = errorTitulo ? 'form-control is-invalid sombra' : 'form-control sombra';
    const botonBuscar = useRef<HTMLButtonElement>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const iconoCargando = <div className="text-center">
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando...</span>
        </div>
    </div>;

    useEffect(() => {
        if (searchParams.size) {
            let paramTitulo = searchParams.get("titulo");
            if (paramTitulo) {
                setTituloJuego(paramTitulo);
            }
            let paramPlataformas = searchParams.get("plataformas");
            if (paramPlataformas) {
                let listaPlataformas = paramPlataformas.split(',').map(x => Number(x));
                if (listaPlataformas && !listaPlataformas.includes(NaN)) {
                    setIdsPlataformas(listaPlataformas);
                }
            }

            let paramFechaDesde = dateService.obtenerDesdeFechaInput(searchParams.get("fechaDesde")!);
            if (paramFechaDesde.isValid()) {
                setFechaDesde(paramFechaDesde.toDate());
            }

            let paramFechaHasta = dateService.obtenerDesdeFechaInput(searchParams.get("fechaHasta")!);
            if (paramFechaHasta.isValid()) {
                setFechaHasta(paramFechaHasta.toDate());
            }       

            setBuscarDesdeUrl(true);
        }
    }, []);

    useEffect(() => {
        if (buscarDesdeUrl) {
            buscarJuegos();
        }       
    }, [buscarDesdeUrl]);

    function buscarJuegos() {
        let errorTitulo = tituloJuego.length === 0;
        setErrorTitulo(errorTitulo);        

        if (!errorTitulo) {
            setCargando(true);
            setSearchParams({
                titulo: tituloJuego,
                plataformas: idsPlataformas.join(','),
                fechaDesde: dateService.formatearFechaInput(fechaDesde),
                fechaHasta: dateService.formatearFechaInput(fechaHasta)
            })
            apiService.buscarAvanzadoJuego(tituloJuego, idsPlataformas, fechaDesde, fechaHasta)
                .then(function (response) {
                    setCargando(false);
                    setResultados(response.data);
                });
        }
    }

    function tituloOnChange(event: React.FormEvent<HTMLInputElement>) {
        setErrorTitulo(false);
        setTituloJuego(event.currentTarget.value);
    }

    function fechaInicioOnChange(event: React.FormEvent<HTMLInputElement>) {
        if (event.currentTarget.valueAsDate) {
            setFechaDesde(event.currentTarget.valueAsDate!);
            if (dayjs(fechaHasta).isBefore(dayjs(event.currentTarget.valueAsDate!), 'date')) {
                setFechaHasta(event.currentTarget.valueAsDate!);
            }
        }
    }

    function fechaFinOnChange(event: React.FormEvent<HTMLInputElement>) {
        if (event.currentTarget.valueAsDate) {
            setFechaHasta(event.currentTarget.valueAsDate);
        }        
    }

    function plataformaOnChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            setIdsPlataformas((prev) => [...prev, Number(event.target.value)])
        }
        else {        
            if (idsPlataformas.length === 1) {
                event.target.checked = true;
            } else {
                setIdsPlataformas((prev) => prev.filter(x => x !== Number(event.target.value)));
            }            
        }
    }

    const handleOnKeyUp = (event: { key: string; }) => {
        if (event.key === 'Enter') {
            buscarJuegos();
            botonBuscar.current?.scrollIntoView({behavior: 'smooth'});
        }
    }

    return (
        <div className="busqueda-avanzada my-2">
            <Helmet prioritizeSeoTags>
                <title>{`${busquedaAvanzada} - ${todoLanzamientos}`}</title>
                <meta property="og:title" content={`${busquedaAvanzada} - ${todoLanzamientos}`} />
            </Helmet>
            <h1 className="titulo-busqueda-avanzada fw-semibold">{busquedaAvanzada}</h1>
            <div className="mb-3">
                <label className="form-label text-start d-block" htmlFor="input-titulo">{titulo}:</label>
                <input                    
                    id="input-titulo"
                    maxLength={100}
                    type="text" className={divTitulo}
                    onChange={tituloOnChange}
                    onKeyUp={handleOnKeyUp}
                    value={tituloJuego}
                />
                <div className="invalid-feedback">{mensajeErrorTitulo}</div>
            </div>

            <div className="mb-3">
                <label className="form-label text-start d-block">{plataforma}:</label>
                {plataformasOrdenadas
                    .map(plataforma =>
                        <div className="form-check form-check-inline" key={`plataforma-${plataforma.idPlataforma}`}>
                            <input className="form-check-input shadow-none sombra" type="checkbox"
                                value={plataforma.idPlataforma} id={`plataforma-${plataforma.idPlataforma}`}
                                onChange={plataformaOnChange}
                                checked={idsPlataformas.includes(plataforma.idPlataforma)}
                            />
                            <label className="form-check-label" htmlFor={`plataforma-${plataforma.idPlataforma}`}>{plataforma.nombre}</label>
                        </div>
                )}
            </div>

            <div className="mb-3">
                <label className="form-label float-start">{fechaLanzamiento}:</label>
            </div>

            <div className="input-group mb-3 justify-content-evenly">
                <div className="col-6 col-md-4 col-lg-3 px-2">
                <span>{desde}</span>
                    <input type="date" className="form-control sombra"
                    min={dateService.formatearFechaInput(new Date())}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={fechaInicioOnChange}
                    value={dateService.formatearFechaInput(fechaDesde)}
                    />
                </div>
                <div className="col-6 col-md-4 col-lg-3 px-2">
                <span>{hasta}</span>
                <input type="date" className="form-control sombra"
                    min={dateService.formatearFechaInput(fechaDesde)}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={fechaFinOnChange}
                    value={dateService.formatearFechaInput(fechaHasta)}
                />
                </div>
            </div>

            <button type="button" ref={botonBuscar} className="btn btn-lg btn-dark m-3 sombra" onClick={buscarJuegos}>{buscar}</button> 

            {cargando && iconoCargando }
            {resultados?.length === 0 && <h3>{noSeHanEncontradoResultados}</h3>}
            {resultados && 
                <div className="row-cols lista-lanzamientos-vertical rounded  sombra">
                    <div className="list-group">
                        {resultados.map(resultado =>
                            <ElementoListaLanzamientoVertical
                                titulo={resultado.titulo}
                                urlImagen={resultado.urlImagen}
                                idJuego={resultado.idJuego}
                                slug={resultado.slug}
                                placeholder={false}
                                key={`resutadoBusqueda_${resultado.idJuego}}`}
                            />)
                        }                        
                    </div>
                </div>
            }
            {resultados?.length === 24 && <span>{resultadosLimitados24}</span> }
        </div>
    )
}