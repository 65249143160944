import { Link, useNavigate } from "react-router-dom";
import "./Paginacion.css"
import { useEffect, useState } from "react";
import ResultadoObtenerArticulosType from "../../types/ResultadoObtenerArticulosType";

export default function PaginacionArticulos({  pagina, resultadoObtenerArticulos }: PaginacionArticulosProps) {
    const navigate = useNavigate();
    const [arrayPaginas, setArrayPaginas] = useState<number[]>([1]);
    const [totalPaginas, setTotalPaginas] = useState<number>(1);

    function obtenerUrlPaginacion(pagina: number) {
        return `/articulos/pagina/${pagina}`;
    }

    useEffect(() => {
        if (resultadoObtenerArticulos && resultadoObtenerArticulos.articulos) {
            setTotalPaginas(resultadoObtenerArticulos ? Math.ceil(resultadoObtenerArticulos.totalResultados / resultadoObtenerArticulos.resultadosPorPagina) : 1);
        }
    }, [resultadoObtenerArticulos]);

    useEffect(() => {
        if (resultadoObtenerArticulos) {
            setArrayPaginas(Array.from(Array(totalPaginas)).map((e, i) => i + 1));
        }
    }, [resultadoObtenerArticulos, totalPaginas]);

    function OnChangePaginadorHandler(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();
        navigate(obtenerUrlPaginacion(Number(event.target.value)));
    }

    const paginacion = () => {
        const paginas = arrayPaginas.map(function (paginaMapeo) {
            return <option key={paginaMapeo} value={paginaMapeo}>{paginaMapeo}</option>;
        })

        const claseAnterior = pagina === 1 ? "page-item disabled" : "page-item";
        const claseSiguiente = pagina === (totalPaginas) ? "page-item disabled" : "page-item";
        return (
            <ul className="pagination justify-content-end flex-wrap">
                <li className={claseAnterior}>
                    <Link className="page-link shadow-none" to={obtenerUrlPaginacion(Number(pagina) - 1)}>Anterior</Link>
                </li>
                <select
                    className="form-select shadow-none"
                    value={pagina}
                    onChange={e => OnChangePaginadorHandler(e)}>
                    {paginas}
                </select>
                <li className={claseSiguiente}>
                    <Link className="page-link shadow-none" to={obtenerUrlPaginacion(Number(pagina) + 1)}>Siguiente</Link>
                </li>
            </ul>
        )
    }

    return paginacion();
}

export interface PaginacionArticulosProps {
    pagina: number,
    resultadoObtenerArticulos: ResultadoObtenerArticulosType | undefined,
}
