import { error404, textoError404, todoLanzamientos } from "../../constants/textos"
import "./Error404.css"
import { Helmet } from "react-helmet-async";

export default function Error404() {
    return (
        <div className="my-2">
            <Helmet prioritizeSeoTags>
                <title>{`${error404} - ${todoLanzamientos}`}</title>
                <meta property="og:title" content={`${error404} - ${todoLanzamientos}`} />
            </Helmet>
            <h1 className="fw-semibold">{error404}</h1>
            <h5>{textoError404}</h5>
            <img
                className="img-fluid my-4"
                alt="Imagen error 404"
                src="/error404.png"></img>
        </div>
    )
}