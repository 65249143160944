import dayjs from "dayjs";
import { formatoFechaBreve, formatoFechaConHora, formatoFechaInput, formatoFechaSoloAño } from "../constants/formatosFecha";

class DateService {
    formatearFechaInput(fecha: Date) {
        return dayjs(fecha).format(formatoFechaInput);        
    }

    obtenerDesdeFechaInput(cadena: string) {
        return dayjs(cadena, formatoFechaInput);
    }

    formatearFechaLanzamiento(fecha: Date) {
        const fechaDayJs = dayjs(fecha);
        if (fechaDayJs.month() === 11 && fechaDayJs.date() === 31) {
            return fechaDayJs.format(formatoFechaSoloAño);
        } else {
            return fechaDayJs.format(formatoFechaBreve);
        }
    }

    formatearFechaConHora(fecha: Date) {
        return dayjs(fecha).format(formatoFechaConHora);
    }

    formatearFechaBreve(fecha: Date) {
        return dayjs(fecha).format(formatoFechaBreve);
    }

    esFechaProvisional(fecha: Date) {
        const fechaDayJs = dayjs(fecha);
        return fechaDayJs.month() === 11 && fechaDayJs.date() === 31;
    }
}

const dateService = new DateService();
export default dateService;

