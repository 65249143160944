import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { plataformasOrdenadas } from '../../constants/plataformas';
import { sinPortada } from '../../constants/sinPortada';
import dateService from '../../services/DateService';
import Plataforma from '../Plataforma/Plataforma';
import './TarjetaLanzamiento.css';

export default function TarjetaLanzamiento(props: LanzamientoProps) {
    const urlImagen = props.urlImagen.length ? props.urlImagen : sinPortada;
    const classDiv = props.placeholder ? 'card placeholder-wave' : 'card';
    const styleLink: CSSProperties | undefined = props.placeholder ? { pointerEvents: "none" } : undefined;
    return (    
        <div className={classDiv} title={props.titulo}>
            <Link
                className="row g-0 h-100 sombra"
                style={styleLink}
                to={`/juegos/${props.idJuego}/${props.slug}`}>
                <img
                    src={urlImagen}
                    alt={props.titulo}
                    className="card-img-top rounded card-portada" />                    
                <div className="card-body d-flex flex-column">
                    <div className="mt-auto">
                        <h5 className="card-title fw-semibold">
                            {props.titulo}
                        </h5>
                        <p className="card-text">
                            {!props.placeholder && dateService.formatearFechaLanzamiento(props.fechaLanzamiento)}
                        </p>
                        <div className="plataformas row row-cols-3 justify-content-center">
                            {props.idsPlataformas && props.mostrarPlataformas &&
                                plataformasOrdenadas
                                .map(plataforma =>
                                    <Plataforma key={plataforma.idPlataforma}
                                        idPlataforma={plataforma.idPlataforma}
                                        deshabilitado={!props.idsPlataformas.includes(plataforma.idPlataforma)}
                                        justifyContent='justify-content-center'
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export interface LanzamientoProps {
    idJuego: number,
    titulo: string,
    urlImagen: string,
    fechaLanzamiento: Date,
    mostrarPlataformas: boolean,
    idsPlataformas: number[],
    slug: string,
    placeholder?: boolean
}
