import { Link } from "react-router-dom";
import "./ElementoListaArticulo.css"
import { CSSProperties } from "react";

export default function ElementoListaArticulo(props: ElementoListaArticuloProps) {
    const classDivElementoListaArticulo = props.placeholder ? "list-group-item elemento-lista-articulo placeholder-wave" : "list-group-item elemento-lista-articulo";
    const classDiv = props.placeholder ? "d-flex placeholder bg-dark" : "d-flex";
    const styleLink: CSSProperties | undefined = props.placeholder ? { pointerEvents: "none" } : undefined;

    return (
        <div className={classDivElementoListaArticulo} title={props.titulo} key={props.idArticulo}>
            <Link to={`/articulo/${props.idArticulo}/${props.slug}`} style={styleLink} >
                <div className={classDiv}>
                    <img className="align-self-center col-4 col-lg-2" src={props.urlImagen} alt={props.titulo} />
                    <div className="col-8 col-lg-10 align-self-center justify-content-start ps-1">
                        <div className="fw-semibold text-start titulo-lista">
                            <span className="align-self-start">{props.titulo}</span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export interface ElementoListaArticuloProps {
    idArticulo: number,
    titulo: string,
    urlImagen: string,
    slug: string,
    placeholder?: boolean
}
