import { plataformas } from "../../constants/plataformas"
import "./Plataforma.css"

export default function Plataforma(props: PlataformaProps) {

    const plataforma = plataformas[props.idPlataforma - 1];
    const divPlataforma = props.deshabilitado ?
        `plataforma d-flex ${props.justifyContent} opacity-10` :
        `plataforma d-flex ${props.justifyContent}`;

    return (
        <div className={divPlataforma} title={plataforma.nombre}>
            <img
                className="img-fluid"
                src={`/icons/${(plataforma.icono)}.png`}
                alt={plataforma.nombre}                
            />            
            {props.mostrarNombreCorto && <span className="ms-2">{plataforma.nombreCorto}</span>}
            {props.mostrarNombre && <span className="ms-2">{plataforma.nombre}</span> }
        </div>
    )
}

export interface PlataformaProps {
    idPlataforma: number,
    mostrarNombreCorto?: boolean,
    mostrarNombre?: boolean,
    deshabilitado: boolean,
    justifyContent: string,
}
