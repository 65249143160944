import { useEffect, useState } from "react";
import "./ListaArticulos.css"
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ApiService, { ObtenerArticulosParams } from "../../services/ApiService";
import { Helmet } from "react-helmet-async";
import { articulos, descripcionArticulos, todoLanzamientos } from "../../constants/textos";
import ElementoListaArticulo from "../ElementoListaArticulo/ElementoListaArticulo";
import ResultadoObtenerArticulosType from "../../types/ResultadoObtenerArticulosType";
import PaginacionArticulos from "../Paginacion/PaginacionArticulos";

export default function ListaArticulos() {
    const [pagina, setPagina] = useState(0);
    const paginaParam = useParams<ObtenerArticulosParams>().pagina ?? "1";
    const navigate = useNavigate();

    const placeholder = [...Array(10)].map((e, i) => <ElementoListaArticulo
        titulo=""
        urlImagen="/placeholder_720.jpg"
        idArticulo={0}
        slug=""
        placeholder={true}
        key={`articulo_placeholder_${i}`}
    />);   

    useEffect(() => {
        if (paginaParam) {
            const numberPagina = Number(paginaParam);
            if (Number.isInteger(numberPagina)) {
                setPagina(numberPagina);
            }
            if (!Number.isInteger(numberPagina) || numberPagina < 1) {
                navigate("/error", { replace: true });
            }
        }
    }, [paginaParam, pagina, navigate]);

    const queryObtenerArticulos = useQuery({
        queryKey: [`obtenerArticulos`, pagina],
        queryFn: async () => {
            if (pagina > 0) {
                const res = await ApiService.obtenerArticulos(Number(pagina));
                return res.data
            }
            return {} as ResultadoObtenerArticulosType;
        },
    });

    return (
        <div className="my-2">
            <Helmet prioritizeSeoTags>
                <title>{`${articulos} - ${todoLanzamientos}`}</title>
                <meta name="description" content={descripcionArticulos} />
                <meta property="og:title" content={`${articulos} - ${todoLanzamientos}`} />
                <meta property="og:description" content={descripcionArticulos} />
            </Helmet>

            <h1 className="titulo-pantalla-lanzamientos mb-4 fw-semibold">{articulos}</h1>

            <PaginacionArticulos
                pagina={pagina}
                resultadoObtenerArticulos={queryObtenerArticulos.data}
                key="paginacion_top"
            />  
            <div className="py-2 rounded">
            { (queryObtenerArticulos.isLoading || queryObtenerArticulos?.data?.articulos.length === 0) && placeholder }
            {
                queryObtenerArticulos.data &&                
                    queryObtenerArticulos.data.articulos?.map(x =>
                        <ElementoListaArticulo
                            key={x.idArticulo}
                            idArticulo={x.idArticulo}
                            slug={x.slug}
                            titulo={x.titulo}
                            urlImagen={x.urlImagen}
                        />
                    )
                }
            </div>

            <PaginacionArticulos
                pagina={pagina}
                resultadoObtenerArticulos={queryObtenerArticulos.data}
                key="paginacion_bottom"
            />   
        </div>
    )

}