import { emailParte1, emailParte2, emailParte3, politicaCookies, todoLanzamientos } from "../../constants/textos";
import "./PoliticaCookies.css"
import { Helmet } from "react-helmet-async";

export default function PoliticaCookies() {
    const correo = <a href={`mailto:${emailParte1}${emailParte2}${emailParte3}`}>{emailParte1}{emailParte2}{emailParte3}</a>;

    return (
        <div className="my-2">
            <Helmet prioritizeSeoTags>
                <title>{`${politicaCookies} - ${todoLanzamientos}`}</title>
                <meta property="og:title" content={`${politicaCookies} - ${todoLanzamientos}`} />
            </Helmet>
            <h1 className="fw-semibold mb-4">{politicaCookies}</h1>
            <div className="text-start">
                <h4 className="fw-semibold">Política de cookies para Todo Lanzamientos</h4>
                <p>
                    Esta es la Política de Cookies de Todo Lanzamientos, accesible desde https://www.todolanzamientos.es.
                </p>
                <h5 className="fw-semibold">Qué son las cookies</h5>
                <p>Como es práctica habitual en casi todos los sitios web profesionales, este sitio utiliza cookies, que son pequeños archivos que se descargan en tu ordenador para mejorar tu experiencia. 
                    Esta página describe qué información recopilan, cómo la utilizamos y por qué a veces necesitamos almacenar estas cookies.
                    También te explicaremos cómo puedes evitar que se almacenen estas cookies, aunque esto puede degradar o "romper" ciertos elementos de la funcionalidad de los sitios.
                </p>
                <h5 className="fw-semibold">Cómo usamos las cookies</h5>
                <p>
                    Utilizamos cookies por una serie de razones que se detallan a continuación.
                </p>
                <h5 className="fw-semibold">Desactivar las cookies</h5>
                <p>
                    Puedes evitar la instalación de cookies ajustando la configuración de tu navegador (consulta la Ayuda de tu navegador para saber cómo hacerlo).
                    Ten en cuenta que deshabilitar las cookies afectará a la funcionalidad de este sitio web y de muchos otros que visite.
                    La desactivación de las cookies suele conllevar también la desactivación de determinadas funciones y características de este sitio.
                    Por lo tanto, te recomendamos que no desactives las cookies desde la configuración del navegador.
                </p>
                <h5 className="fw-semibold">Las cookies que utilizamos</h5>
                <ul>
                    <li>
                        <strong>Cookies de preferencias del sitio web. </strong>Con el fin de proporcionarte una gran experiencia en este sitio, te proporcionamos la funcionalidad para establecer tu preferencias sobre cómo funciona este sitio cuando lo utilizas.
                        Con el fin de recordar tus preferencias, tenemos que configurar las cookies para que esta información se pueda recuperar cada vez que interactúas con una página se ve afectada por tus preferencias.
                        Estas cookies son esenciales para el funcionamiento del sitio y, por lo tanto, no se pueden rechazar.
                    </li>
                </ul>
                <h5 className="fw-semibold">Cookies de terceros</h5>
                <p>
                    En algunos casos especiales también utilizamos cookies proporcionadas por terceros de confianza. Estas cookies son opcionales y pueden aceptarse o rechazarse cuando se accede al sitio web por primera vez o pasan unos determinados días desde que se establecieron.
                    En ningún caso las activamos si no has dado consentimiento para ello.
                    La siguiente sección detalla qué cookies de terceros puedes encontrar en este sitio web.
                </p>
                <ul>
                    <li>
                        El servicio Google AdSense que utilizamos para publicar anuncios utiliza una cookie de DoubleClick para publicar anuncios más relevantes en la web y limitar el número de veces que se te muestra un anuncio determinado.
                    </li>                    
                </ul>
                <h5>Contacto</h5>
                <p>Si tienes alguna pregunta o sugerencia sobre nuestra Política de cookies, no dudes en ponerte en contacto con nosotros escribiendo a la dirección de correo {correo}.</p>
            </div>
        </div>
    )
}