import "./Offcanvas.css";
import 'bootstrap/js/dist/offcanvas';
import OffcanvasBootstrap from 'bootstrap/js/dist/offcanvas'; 
import { articulos, busquedaAvanzada, inicio, lanzamientosRecientes, proximosLanzamientos } from "../../constants/textos";
import Buscador from "../Buscador/Buscador";
import { Link, useLocation } from "react-router-dom";
import { plataformasOrdenadas } from "../../constants/plataformas";
import Plataforma from "../Plataforma/Plataforma";
import { useEffect } from "react";
import Logo from "../Logo/Logo";

export default function Offcanvas() {
    const location = useLocation();

    useEffect(() => {
        const offCanvas = OffcanvasBootstrap.getInstance("#offcanvasMenu");
        offCanvas?.hide();
    }, [location]);

    return (
        <div className="offcanvas offcanvas-start sombra" tabIndex={-1} id="offcanvasMenu">
            <div className="offcanvas-header">
                <button type="button" className="btn-close btn-close-white"
                    data-bs-dismiss="offcanvas" aria-label="Cerrar" />
                <Logo />
            </div>
            <div className="offcanvas-body">
                <div className="d-block d-lg-none mb-3">
                    <Buscador id="buscador-offcanvas" />
                </div>
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">{inicio}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/lanzamientos/pagina/1">{proximosLanzamientos}</Link>
                    </li>
                    <ul>
                        {plataformasOrdenadas
                            .map(plataforma =>
                                <li className="nav-item" key={plataforma.idPlataforma}>
                                    <Link                                        
                                        className="nav-link"
                                        to={`/lanzamientos/plataforma/${plataforma.idPlataforma}/${plataforma.slug}/pagina/1`}>
                                        <Plataforma
                                            idPlataforma={plataforma.idPlataforma}
                                            mostrarNombre={true}
                                            deshabilitado={false}
                                            justifyContent='justify-content-start'
                                        />
                                    </Link>
                                </li>
                            )}
                    </ul>
                    <li className="nav-item">
                        <Link className="nav-link" to="/lanzamientos-recientes/pagina/1">{lanzamientosRecientes}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/buscar">{busquedaAvanzada}</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}