import { Link, useNavigate, useParams } from "react-router-dom";
import Markdown from 'react-markdown';
import "./DetalleArticulo.css"
import { useEffect } from "react";
import ApiService, { ObtenerDetalleArticuloParams } from "../../services/ApiService";
import { useQuery } from "@tanstack/react-query";
import dateService from "../../services/DateService";
import { Helmet } from "react-helmet-async";
import { todoLanzamientos, verMasArticulos } from "../../constants/textos";

export default function DetalleArticulo() {
    const { idArticulo } = useParams<ObtenerDetalleArticuloParams>();
    const navigate = useNavigate();

    useEffect(() => {
        const numberIdArticulo = Number(idArticulo);
        if (!Number.isInteger(numberIdArticulo)) {
            navigate("/error", { replace: true });
        }
    }, [idArticulo, navigate]);

    const {
        data
    } = useQuery({
        queryKey: [`obtenerDetalleJuego`, idArticulo],
        queryFn: async () => {
            const res = await ApiService.obtenerArticulo(idArticulo!);
            if (!res.data) {
                navigate("/error", { replace: true });
            }
            return res.data
        }
    });         

    return (
        <div className="my-2">
            {data &&
                <div className="detalle-articulo mx-auto d-block">
                    <Helmet prioritizeSeoTags>                        
                        <title>{`${data.titulo} - ${todoLanzamientos}`}</title>
                        <meta property="og:title" content={`${data.titulo} - ${todoLanzamientos}`} />
                        <meta property="og:image" content={`https://www.todolanzamientos.es${data.urlImagen}`} />
                        <meta name="description" content={data.titulo} />
                        <meta property="og:description" content={data.titulo} />
                        <meta property="og:type" content="article" />
                    </Helmet>
                    <article className="text-start pb-5">
                        <h1 className="mb-2 fw-semibold">{data.titulo}</h1>
                        <p className="text-start">{dateService.formatearFechaConHora(data.fechaPublicacion)}</p>                    
                        <img className="img-fluid rounded mx-auto d-block m-4 sombra" src={data.urlImagen} alt={data.titulo} />
                        <Markdown
                            components={{ 
                                img(props) {
                                    return <img src={props.src} className="img-fluid rounded mx-auto d-block m-2 sombra" alt={props.alt}></img>
                                }
                            }}
                        >{data.contenido}</Markdown>
                    </article>
                    <div className="text-start text-start">
                        <Link to="/articulos/pagina/1">{verMasArticulos}</Link>
                    </div>
                </div>
            }
        </div>
    )
}