import "./Buscador.css";
import React, { useEffect, useState, useRef } from 'react';
import ApiService from '../../services/ApiService';
import ResultadoBusquedaJuegoType from '../../types/ResultadoBusquedaJuegoType';
import { Link, useNavigate, } from "react-router-dom";
import { sinPortadaMiniatura } from "../../constants/sinPortada";
import { accedeABusquedaAvanzada, noSeHanEncontradoResultados } from "../../constants/textos";

export default function Buscador(props : BuscadorProps) {
    const [busqueda, setBusqueda] = useState("");
    const [resultadosBusqueda, setResultadosBusqueda] = useState<ResultadoBusquedaJuegoType[]>([]);
    const [esResultadoBusquedaVisible, setEsResultadoBusquedaVisible] = useState(false);
    const [mostrarResultadosNoEncontrados, setMostrarResultadosNoEncontrados] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    function buscadorOnChange(event: React.FormEvent<HTMLInputElement>) {
        setBusqueda(event.currentTarget.value);
    }

    function accederABusquedaAvanzada() {
        setBusqueda("");
        navigate("/buscar");
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setEsResultadoBusquedaVisible(false);
        }
        else if (ref.current){
            setEsResultadoBusquedaVisible(true);
        }
    };

    useEffect(() => {
        setEsResultadoBusquedaVisible(false);
        setMostrarResultadosNoEncontrados(false);
        if (busqueda.length > 0) {
            var obtenerDatos = setTimeout(() => {
                ApiService
                    .buscarJuego(busqueda)
                    .then(response => {
                        setResultadosBusqueda(response.data);
                        setEsResultadoBusquedaVisible(true);
                        if (response.data.length === 0) {
                            setMostrarResultadosNoEncontrados(true);
                        }
                    });
            }, 1000)
        }
        else {
            setResultadosBusqueda([]);
        }

        return () => clearTimeout(obtenerDatos)
    }, [busqueda]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    function onClickHandler() {
        setEsResultadoBusquedaVisible(false);
    }
    const urlImagen = function (urlImagen: string) {
        return urlImagen?.length ? urlImagen : sinPortadaMiniatura;
    }
    const resultadosHtml = resultadosBusqueda.map(resultadoBusqueda =>
        <Link
            key={resultadoBusqueda.idJuego}
            className="resultado-busqueda"
            to={`/juegos/${resultadoBusqueda.idJuego}/${resultadoBusqueda.slug}`}
            onClick={onClickHandler}
            title={resultadoBusqueda.titulo}
        >            
            <div className="d-flex justify-content-start media">
                <img
                    className="align-self-center mr-3"
                    src={urlImagen(resultadoBusqueda.urlImagen)}
                    alt="{resultadoBusqueda.titulo}" />
                <div className="media-body align-self-center text-truncate">
                    <span className="fw-semibold">{resultadoBusqueda.titulo}</span>
                </div>
            </div>
        </Link>
    );

    return (        
        <div className="d-flex input-group w-auto barra-buscador" ref={ref}>
            <input
                id={props.id}
                maxLength={100}
                type="search"
                className="form-control rounded sombra"
                placeholder="Buscar..."
                aria-label="Buscar"
                value={busqueda}
                onChange={buscadorOnChange}                
            >                
            </input> 
            {esResultadoBusquedaVisible && resultadosBusqueda.length > 0 &&
                <div className="resultados-busqueda d-flex flex-column w-100 sombra">
                    {resultadosHtml}
                </div>
            }
            {esResultadoBusquedaVisible && mostrarResultadosNoEncontrados &&
                <div className="resultados-busqueda d-flex flex-column w-100 sombra">
                    <div className="resulado-busqueda-sin-resultados">
                        <div className="d-flex justify-content-start ">
                            <div className="media-body align-self-center m-1">                                
                                <p>{noSeHanEncontradoResultados}</p>
                                <span className='text-break'>
                                    <a
                                        href="#"
                                        className='text-break'
                                        onClick={accederABusquedaAvanzada}>
                                        {accedeABusquedaAvanzada}
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>                
            }
            </div>
    )
}

export interface BuscadorProps {
    id: string
}
