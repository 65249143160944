import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { plataformas } from '../../constants/plataformas';
import { sinPortada } from '../../constants/sinPortada';
import { anadirCalendario, avisoPosibleCambioFecha, fechaLanzamiento, genero, lanzamientoDe, noSeHaEncontradoUnaDescripcionJuego, todoLanzamientos, verEnTodoLanzamientos } from '../../constants/textos';
import ApiService, { ObtenerJuegoParams } from '../../services/ApiService';
import dateService from '../../services/DateService';
import Plataforma from '../Plataforma/Plataforma';
import Video from '../Video/Video';
import './DetalleJuego.css'
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Helmet } from "react-helmet-async";

export default function DetalleJuego() {
    const { idJuego } = useParams<ObtenerJuegoParams>();
    const navigate = useNavigate();

    useEffect(() => {
        const numberIdJuego = Number(idJuego);
        if (!Number.isInteger(numberIdJuego)) {
            navigate("/error", { replace: true });
        }
    }, [idJuego, navigate]);

    const {
        data
    } = useQuery({
        queryKey: [`obtenerDetalleJuego`, idJuego],
        queryFn: async () => {
            const res = await ApiService.obtenerJuego(idJuego!);
            if (!res.data) {
                navigate("/error", { replace: true });
            }
            return res.data
        }
    });         

    const urlImagen = data?.urlImagen?.length ? data.urlImagen : sinPortada;

    function calendarioDiv(date: Date) {
        return (dateService.esFechaProvisional(date) || dayjs(date).add(1, 'days').isBefore(dayjs())) ?
            "d-none" : "";
    }

    const placeholder = 
        <div className="detalle-juego my-2 sombra placeholder-wave">
            <h1 className="placeholder bg-dark"></h1>
            <div className="row">
                <div className="col-lg-4">
                    <img className="mb-3 sombra placeholder bg-dark" src="/placeholder_tarjeta.jpg"/>
                    <h5 className="m-1 placeholder bg-dark"></h5>
                </div>
                <div className="col-lg-8">
                    <p className="m-3 text-start placeholder bg-dark"></p>
                </div>
            </div>
        </div>;

    return (
        <div>
            {!data && placeholder }
            {
                data &&
                    <div className="detalle-juego my-2 sombra">  
                        <Helmet prioritizeSeoTags>
                            <title>{`${data.titulo} - ${todoLanzamientos}`}</title>
                            {data.descripcion && <meta name="description" content={data.descripcion} />}
                            <meta property="og:title" content={`${data.titulo} - ${todoLanzamientos}`} />
                            <meta property="og:image" content={`https://www.todolanzamientos.es${urlImagen}`} />
                            {data.descripcion && <meta property="og:description" content={data.descripcion} />}
                        </Helmet>
                        <h1 className="fw-semibold mb-4">{data.titulo}</h1>
                        <div className="row">
                            <div className="col-lg-4">
                                <img className="mb-3 sombra" src={urlImagen} alt={data.titulo} />
                                {data.generos.length > 0 &&
                                    <div className="m-1">
                                        <h5 className="m-1">{genero}</h5>                                        
                                        <span>{data.generos.join(", ")}</span>
                                    </div>
                                }
                                    <div className="m-2 fechas-lanzamiento">
                                    <h5 className="m-1">{fechaLanzamiento}</h5>
                                    {data.fechasLanzamiento
                                        .sort((a, b) => plataformas[a.idPlataforma - 1].nombre.toLowerCase().localeCompare(plataformas[b.idPlataforma - 1].nombre.toLowerCase()))
                                        .map(fechalanzamiento =>
                                            <div key={fechalanzamiento.idPlataforma}
                                                className="fecha-lanzamiento d-flex justify-content-between">
                                                <div className="align-self-center">
                                                    <Plataforma
                                                        idPlataforma={fechalanzamiento.idPlataforma}
                                                        mostrarNombreCorto={false}
                                                        deshabilitado={false}
                                                        justifyContent='justify-content-start'
                                                    />
                                                </div>
                                                <div className="d-flex">
                                                    <span className="align-self-center p-2">{dateService.formatearFechaLanzamiento(fechalanzamiento.fechaLanzamiento)}</span>                                        
                                                    <div className={calendarioDiv(fechalanzamiento.fechaLanzamiento)} title={anadirCalendario}>
                                                        <AddToCalendarButton
                                                            name={`${lanzamientoDe} "${data.titulo}" (${plataformas[fechalanzamiento.idPlataforma - 1].nombre})`}
                                                            description={`[h1]${data.titulo}[/h1][br][p]${data.descripcion}[/p][br][url]${window.location.href}|${verEnTodoLanzamientos}[/url][br][br][i]${avisoPosibleCambioFecha}[/i]`}
                                                            startDate={dateService.formatearFechaInput(fechalanzamiento.fechaLanzamiento)}
                                                            timeZone="Europe/Madrid"
                                                            options={['Google', 'Apple', 'Outlook.com']}
                                                            trigger="click"
                                                            hideTextLabelButton
                                                            hideBackground
                                                            lightMode="dark"
                                                            language="es"
                                                            size="1"
                                                            hideBranding={true}
                                                        ></AddToCalendarButton>
                                                    </div>  
                                                    </div>
                                            </div>
                                            )}
                                    </div>
                            </div>
                                <div className="col-lg-8">
                                {data.idVideo && <div className="mx-3 mb-3"><Video idVideo={data.idVideo} /></div>}
                                {data.descripcion.length > 0 && <p className="m-3 text-start">{data.descripcion}</p>}
                                {data.descripcion.length === 0 && <p className="m-3 text-start fst-italic">{noSeHaEncontradoUnaDescripcionJuego}</p>}
                            </div>
                        </div>
                    </div>
            }    
        </div>
    )
}
