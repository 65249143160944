import './Video.css'

export default function Video({ idVideo }: VideoProps) {
    return (
        <div className="video-container">                   
            <iframe
                className="sombra"
                src={`https://www.youtube-nocookie.com/embed/${idVideo}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen>
            </iframe>                
        </div>
    );
}

export interface VideoProps {
    idVideo: string,
}
