import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { lanzamientosRecientes, proximosLanzamientos, verTodos } from "../../constants/textos";
import ApiService from "../../services/ApiService";
import ElementoListaLanzamientoVertical from "../ElementoListaLanzamientoVertical/ElementoListaLanzamientoVertical";
import "./ListaLanzamientosVertical.css"

export default function ListaLanzamientosVertical() {
    const queryObtenerLanzamientosRecientes = useQuery({
        queryKey: [`obtenerLanzamientosRecientesPrincipal`],
        queryFn: async () => {            
                const res = await ApiService.obtenerLanzamientosRecientes(1, true);
                return res.data            
        },
    }); 

    const queryObtenerLanzamientosProximos = useQuery({
        queryKey: [`obtenerLanzamientosProximosPrincipal`],
        queryFn: async () => {
            const res = await ApiService.obtenerLanzamientos(1, true);
            return res.data
        },
    });
       
    return (
        <div className="row row-cols row-cols-lg-2 lista-lanzamientos-vertical rounded">            
            <div className="my-2">
                <h4 className="m-2">{proximosLanzamientos}</h4>
                <div className="list-group my-3 sombra">
                    {queryObtenerLanzamientosProximos.isLoading &&
                        [...Array(12)].map((e, i) => <ElementoListaLanzamientoVertical
                            titulo=""
                            urlImagen="/placeholder_tarjeta.jpg"
                            idJuego={0}
                            fechaLanzamiento={new Date()}
                            idsPlataformas={[]}
                            slug=""
                            placeholder={true}
                            key={`lanzamientoProximo_placeholder_${i}`}
                        />)
                }
                {queryObtenerLanzamientosProximos.data &&                    
                    queryObtenerLanzamientosProximos.data.lanzamientos.map(x =>
                        <ElementoListaLanzamientoVertical
                            titulo={x.titulo}
                            urlImagen={x.urlImagen}
                            idJuego={x.idJuego}
                            fechaLanzamiento={x.fechaLanzamiento}
                            idsPlataformas={x.idsPlataformas}
                            slug={x.slug}
                            key={`lanzamientoReciente_${x.idJuego}_${x.idsPlataformas.join(",")}`}
                        />
                    )                    
                }
                </div>
                <Link to="/lanzamientos/pagina/1">
                    <button type="button" className="btn btn-lg btn-dark mb-2 sombra">{verTodos}</button>
                </Link>
            </div>
            <div className="my-2">
                <h4 className="m-2">{lanzamientosRecientes}</h4>
                <div className="list-group my-3 sombra">
                    {queryObtenerLanzamientosRecientes.isLoading &&
                        [...Array(12)].map((e, i) => <ElementoListaLanzamientoVertical
                            titulo=""
                            urlImagen="/placeholder_tarjeta.jpg"
                            idJuego={0}
                            fechaLanzamiento={new Date()}
                            idsPlataformas={[]}
                            slug=""
                            placeholder={true}
                            key={`lanzamientoReciente_placeholder_${i}`}
                        />)
                    }
                    {queryObtenerLanzamientosRecientes.data &&
                        queryObtenerLanzamientosRecientes.data.lanzamientos.map(x =>
                            <ElementoListaLanzamientoVertical
                                titulo={x.titulo}
                                urlImagen={x.urlImagen}
                                idJuego={x.idJuego}
                                idsPlataformas={x.idsPlataformas}
                                slug={x.slug}
                                key={`lanzamientoReciente_${x.idJuego}_${x.idsPlataformas.join(",")}`}
                            />
                        )
                    }
                </div>
                <Link to="/lanzamientos-recientes/pagina/1">
                    <button type="button" className="btn btn-lg btn-dark mb-2 sombra">{verTodos}</button>
                </Link>
            </div>  
        </div>
    )
}