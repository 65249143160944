import { Link, useNavigate } from "react-router-dom";
import ResultadoObtenerLanzamientosType from "../../types/ResultadoObtenerLanzamientosType";
import "./Paginacion.css"
import { useEffect, useState } from "react";
import { plataformas } from "../../constants/plataformas";

export default function PaginacionLanzamientos({ idPlataforma, pagina, resultadoObtenerLanzamientos, prefijoRuta } : PaginacionLanzamientosProps) {
    const navigate = useNavigate();
    const [arrayPaginas, setArrayPaginas] = useState<number[]>([1]);
    const [totalPaginas, setTotalPaginas] = useState<number>(1);

    function obtenerUrlPaginacion(prefijoRuta: string, pagina: number, idPlataforma: string | undefined) {
        if (idPlataforma) {
            const slug = plataformas[Number(idPlataforma) - 1].slug;
            return `/${prefijoRuta}/plataforma/${idPlataforma}/${slug}/pagina/${pagina}`;
        }
        else {
            return `/${prefijoRuta}/pagina/${pagina}`;
        }                  
    }

    useEffect(() => {
        if (resultadoObtenerLanzamientos && resultadoObtenerLanzamientos.lanzamientos) {
            setTotalPaginas(resultadoObtenerLanzamientos ? Math.ceil(resultadoObtenerLanzamientos.totalResultados / resultadoObtenerLanzamientos.resultadosPorPagina) : 1);
        }
    }, [resultadoObtenerLanzamientos]);

    useEffect(() => {
        if (resultadoObtenerLanzamientos) {
            setArrayPaginas(Array.from(Array(totalPaginas)).map((e, i) => i + 1));
        }
    }, [resultadoObtenerLanzamientos, totalPaginas]);

    function OnChangePaginadorHandler(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();
        navigate(obtenerUrlPaginacion(prefijoRuta, Number(event.target.value), idPlataforma));
    }

    const paginacion = () => {
        const paginas = arrayPaginas.map(function (paginaMapeo) {
            return <option key={paginaMapeo} value={paginaMapeo}>{paginaMapeo}</option>;
        })

        const claseAnterior = pagina === 1 ? "page-item disabled" : "page-item";
        const claseSiguiente = pagina === (totalPaginas) ? "page-item disabled" : "page-item";
        return (
            <ul className="pagination justify-content-end flex-wrap">
                <li className={claseAnterior}>
                    <Link className="page-link shadow-none" to={obtenerUrlPaginacion(prefijoRuta, Number(pagina) - 1, idPlataforma)}>Anterior</Link>
                </li>
                <select
                    className="form-select shadow-none"
                    value={pagina}
                    onChange={e => OnChangePaginadorHandler(e)}>
                    {paginas}
                </select>
                <li className={claseSiguiente}>
                    <Link className="page-link shadow-none" to={obtenerUrlPaginacion(prefijoRuta, Number(pagina) + 1, idPlataforma)}>Siguiente</Link>
                </li>
            </ul>
        )        
    }

    return paginacion();
}

export interface PaginacionLanzamientosProps {
    pagina: number,
    idPlataforma?: string | undefined,
    resultadoObtenerLanzamientos: ResultadoObtenerLanzamientosType | undefined,
    prefijoRuta: string
}
