import "./ListaPlataformas.css"
import { plataformasOrdenadas } from "../../constants/plataformas"
import Plataforma from "../Plataforma/Plataforma"
import { Link } from "react-router-dom"

export default function ListaPlataformas() {
    return (        

        <div className="d-flex flex-column h-100">
            <div className="lista-plataformas list-group sombra flex-grow-1">
                {plataformasOrdenadas
                    .map(plataforma =>
                        <Link key={plataforma.idPlataforma} className="page-link list-group-item list-group-item-action" to={`/lanzamientos/plataforma/${plataforma.idPlataforma}/${plataforma.slug}/pagina/1`}>
                            <Plataforma
                                idPlataforma={plataforma.idPlataforma}                                
                                mostrarNombre={true} 
                                deshabilitado={false}
                                justifyContent='justify-content-between'
                            />
                        </Link>
                    )
                }
            </div>
        </div>
    )
}