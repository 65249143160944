import "bootstrap/dist/css/bootstrap.min.css";
import { ScrollRestoration, useLocation } from "react-router-dom";
import './App.css';
import Header from './components/Header/Header'
import Main from "./components/Main/Main";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Offcanvas from "./components/Offcanvas/Offcanvas";
import Footer from "./components/Footer/Footer";
import EasterEgg from "./components/EasterEgg/EasterEgg";
import { Helmet } from "react-helmet-async";
import { descripcionWeb, todoLanzamientos } from "./constants/textos";

function App() { 
    const location = useLocation();
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 5 * (60 * 1000),
                cacheTime: 10 * (60 * 1000),
            }
        }
    });    
    document.body.style.background = '#11151c';

    return (
        <div className="app" data-bs-theme="dark">     
            <Helmet prioritizeSeoTags>
                <title>{todoLanzamientos}</title>
                <meta name="description" content={descripcionWeb} />
                <meta property="og:title" content={todoLanzamientos} />
                <meta property="og:description" content={descripcionWeb} />
                <meta property="og:image" content="https://www.todolanzamientos.es/icon-512-maskable.png" />
                <meta property="og:url" content={`${window.location.protocol}//${window.location.host}${location.pathname}`} />
                <link rel="canonical" href={`${window.location.protocol}//${window.location.host}${location.pathname}`} />
            </Helmet>
            <ScrollRestoration />
            <Header />
            <QueryClientProvider client={queryClient}>
                <Main />
            </QueryClientProvider>
            <Footer />
             <Offcanvas />
            <EasterEgg />
        </div>
    );
}

export default App;

declare global {
    interface Window {
        _mtm: any;
    }
}