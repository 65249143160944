import "./ListaLanzamientosTarjetas.css"
import ApiService, { ObtenerLanzamientosParams } from "../../services/ApiService";
import TarjetaLanzamiento from "../TarjetaLanzamiento/TarjetaLanzamiento";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import PaginacionLanzamientos from "../Paginacion/PaginacionLanzamientos";
import { descripcionProximosLanzamientos, perfectoNovedades, proximosLanzamientos, todoLanzamientos } from "../../constants/textos";
import { plataformas, plataformasOrdenadas } from "../../constants/plataformas";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import ResultadoObtenerLanzamientosType from "../../types/ResultadoObtenerLanzamientosType";

export default function ListaLanzamientosTarjetas() {    
    const [pagina, setPagina] = useState(0);
    const paginaParam = useParams<ObtenerLanzamientosParams>().pagina ?? "1";
    const idPlataforma = useParams<ObtenerLanzamientosParams>().idPlataforma ?? undefined;    
    const navigate = useNavigate();

    useEffect(() => {
        if (paginaParam) {
            const numberPagina = Number(paginaParam);
            if (Number.isInteger(numberPagina)) {
                setPagina(numberPagina);
            }
            if (!Number.isInteger(numberPagina) || numberPagina < 1 || (idPlataforma !== undefined && !esIdPlataformaValida(idPlataforma))) {
                navigate("/error", { replace: true });
            }
        }
    }, [paginaParam, pagina, idPlataforma, navigate]);

    function obtenerTitulo() {
        return idPlataforma && esIdPlataformaValida(idPlataforma) ?
            `${proximosLanzamientos} ${plataformas[Number(idPlataforma)-1].nombre}` :
            proximosLanzamientos;
    }

    function esIdPlataformaValida(idPlataforma: string | undefined) {
        const numberIdPlataforma = Number(idPlataforma);
        return Number.isInteger(numberIdPlataforma) && numberIdPlataforma > 0 && numberIdPlataforma <= plataformas.length;
    }

    function obtenerDescripcion() {
        const formateador = new Intl.ListFormat('es', { style: 'long', type: 'conjunction' });
        const nombresPlataformas = plataformasOrdenadas.map(x => x.nombre);

        return idPlataforma ?
            `${descripcionProximosLanzamientos} ${plataformas[Number(idPlataforma) - 1].nombre}. ${perfectoNovedades}`
            : `${descripcionProximosLanzamientos} ${formateador.format(nombresPlataformas)}. ${perfectoNovedades}`
    }

    const placeholder = [...Array(24)].map((e, i) => <TarjetaLanzamiento
        titulo=""
        urlImagen="/placeholder_tarjeta.jpg"
        idJuego={0}
        fechaLanzamiento={new Date()}
        mostrarPlataformas={idPlataforma === undefined}
        idsPlataformas={[]}
        slug=""
        placeholder={true}
        key={`lanzamiento_placeholder_${i}`}
    />);

    const {
        data,        
        isLoading
      } = useQuery({
          queryKey: [`obtenerLanzamientos`, pagina, idPlataforma],
          queryFn: async () => {
              if (pagina > 0) {
                  if (idPlataforma) {
                      const res = await ApiService.obtenerLanzamientosPorPlataforma(Number(pagina), Number(idPlataforma));
                      return res.data
                  } else {
                      const res = await ApiService.obtenerLanzamientos(Number(pagina), false);
                      return res.data
                  }  
              }
              return {} as ResultadoObtenerLanzamientosType;
        },        
      });     
        
    return (      
        <div className="my-2">       
            <Helmet prioritizeSeoTags>
                <title>{`${obtenerTitulo()} - ${todoLanzamientos}`}</title>
                <meta name="description" content={obtenerDescripcion()} />
                <meta property="og:title" content={`${obtenerTitulo()} - ${todoLanzamientos}`} />
                <meta property="og:description" content={obtenerDescripcion()} />
            </Helmet>
            <h1 className="titulo-pantalla-lanzamientos mb-4 fw-semibold">{obtenerTitulo()}</h1>

            <PaginacionLanzamientos
                idPlataforma={idPlataforma}
                pagina={pagina}
                resultadoObtenerLanzamientos={data}
                prefijoRuta="lanzamientos"
                key="paginacion_top"
            />            
                
            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6">   
                {isLoading && placeholder}
                {
                    data?.lanzamientos?.map((lanzamiento) => (
                        <TarjetaLanzamiento key={`${lanzamiento.idJuego}-${lanzamiento.fechaLanzamiento}`}
                            idJuego={lanzamiento.idJuego}
                            titulo={lanzamiento.titulo}
                            urlImagen={lanzamiento.urlImagen}
                            fechaLanzamiento={lanzamiento.fechaLanzamiento}
                            idsPlataformas={lanzamiento.idsPlataformas}
                            slug={lanzamiento.slug}
                            mostrarPlataformas={idPlataforma === undefined}
                        />
                    ))
                }                   
            </div>

            <PaginacionLanzamientos
                idPlataforma={idPlataforma}
                pagina={pagina}
                resultadoObtenerLanzamientos={data}
                prefijoRuta="lanzamientos"
                key="paginacion_bottom"
            />          
            
        </div>
    )
}