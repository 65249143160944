import { acercaDe, acercaDeParrafo1, acercaDeParrafo2, acercaDeParrafo3, acercaDeParrafo4, emailParte1, emailParte2, emailParte3, todoLanzamientos } from "../../constants/textos"
import "./AcercaDe.css"
import { Helmet } from "react-helmet-async";

export default function AcercaDe() {
    const correo = <a href={`mailto:${emailParte1}${emailParte2}${emailParte3}`}>{emailParte1}{emailParte2}{emailParte3}</a>;
    return (
        <div className="my-2">
            <Helmet prioritizeSeoTags>
                <title>{`${acercaDe} - ${todoLanzamientos}`}</title>
                <meta property="og:title" content={`${acercaDe} - ${todoLanzamientos}`} />
            </Helmet>
            <h1 className="fw-semibold mb-4">{acercaDe}</h1>
            <div className="row row-cols align-items-center my-2">
                <div className="col-4 col-lg-2">
                    <img alt="logo" className="img-fluid mb-4" src="/icon-512.png"></img>
                </div>
                <div className="col-8 col-lg-10">
                    <h4>{acercaDeParrafo1}</h4>                    
                </div>
            </div>
            <p className="text-start my-2">{acercaDeParrafo2}</p>
            <p className="text-start my-2">{acercaDeParrafo3}</p>
            <p className="text-start my-2">{`${acercaDeParrafo4}`} {correo}.</p>

        </div>
    )
}