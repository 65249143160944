import { emailParte1, emailParte2, emailParte3, politicaPrivacidad, todoLanzamientos } from "../../constants/textos"
import "./PoliticaPrivacidad.css"
import { Helmet } from "react-helmet-async";

export default function PoliticaPrivacidad() {
    const correo = <a href={`mailto:${emailParte1}${emailParte2}${emailParte3}`}>{emailParte1}{emailParte2}{emailParte3}</a>;

    return (
        <div className="my-2">
            <Helmet prioritizeSeoTags>
                <title>{`${politicaPrivacidad} - ${todoLanzamientos}`}</title>
                <meta property="og:title" content={`${politicaPrivacidad} - ${todoLanzamientos}`} />
            </Helmet>
            <h1 className="fw-semibold mb-4">{politicaPrivacidad}</h1>
            <div className="text-start">
                <h4 className="fw-semibold">Política de privacidad para Todo Lanzamientos</h4>
                <p>
                    En Todo Lanzamientos, accesible desde https://www.todolanzamientos.es, una de nuestras principales prioridades es la privacidad de nuestros visitantes.
                    Este documento de Política de Privacidad contiene los tipos de información que son recogidos y registrados por Todo Lanzamientos y cómo la utilizamos.
                </p>
                <p>
                    Esta Política de Privacidad se aplica únicamente a nuestras actividades en línea y es válida para los visitantes de nuestro sitio web en lo que respecta a la información que compartieron y/o se recogen en Todo Lanzamientos. Esta política no es aplicable a ninguna información recopilada fuera de línea o a través de canales distintos de este sitio web.
                </p>
                <h5 className="fw-semibold">Consentimiento</h5>
                <p>
                    Al utilizar nuestro sitio web, aceptas nuestra Política de privacidad y estás de acuerdo con sus términos.
                </p>
                <h5 className="fw-semibold">Información que recogemos</h5>
                <p>
                    La información personal que se te pide que proporciones, y las razones por las que se te pide que la proporciones, se te aclararán en el momento en que te pidamos que proporciones tu información personal.
                </p>
                <p>
                    Si te pones en contacto con nosotros directamente, es posible que recibamos información adicional sobre ti, como tu nombre, dirección de correo electrónico, el contenido del mensaje y/o los archivos adjuntos que nos envíes y cualquier otra información que decidas facilitarnos.
                </p>
                <h5 className="fw-semibold">Cómo utilizamos tu información</h5>
                <p>El Reglamento General de Protección de Datos (RGPD) nos obliga a explicar las bases jurídicas válidas en las que nos basamos para procesar tu información personal. Por lo tanto, podemos basarnos en las siguientes bases jurídicas para procesar tu información personal:</p>
                <ul>
                    <li>
                        <strong>Consentimiento. </strong>Podemos procesar tu información si nos has dado permiso (es decir, consentimiento) para utilizar tus datos personales con un fin específico. Puedes retirar tu consentimiento en cualquier momento.
                    </li>
                    <li>
                        <strong>Intereses legítimos. </strong> Podemos procesar tu información cuando creamos que es razonablemente necesario para lograr nuestros intereses comerciales legítimos y dichos intereses no prevalezcan sobre tus intereses y derechos y libertades fundamentales.
                        Por ejemplo, podemos procesar tu información personal para algunos de los fines descritos con el fin de:
                        <ul>
                            <li>
                                Desarrollar y mostrar contenidos publicitarios personalizados y relevantes para nuestros usuarios
                            </li>
                            <li>
                                Analizar cómo se utilizan nuestros servicios para poder mejorarlos para atraer y conservar a los usuarios
                            </li>
                            <li>
                                Diagnosticar problemas y/o prevenir actividades fraudulentas
                            </li>
                            <li>
                                Entender cómo utilizan nuestros usuarios nuestros productos y servicios para poder mejorar la experiencia de usuario
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Obligaciones legales. </strong>Podremos tratar tus datos cuando lo consideremos necesario para el cumplimiento de nuestras obligaciones legales, como cooperar con un cuerpo de seguridad o agencia reguladora, ejercer o defender nuestros derechos legales, o revelar tu información como prueba en un litigio en el que estemos implicados.
                    </li>
                    <li>
                        <strong>Intereses vitales. </strong> Podemos procesar tu información cuando creamos que es necesario para proteger tus intereses vitales o los intereses vitales de un tercero, como situaciones que impliquen amenazas potenciales a la seguridad de cualquier persona.
                    </li>
                </ul>
                <h5 className="fw-semibold">Cuándo y con quién compartimos tu información personal</h5>
                <p><strong>Vendedores, consultores y otros terceros proveedores de servicios. </strong>
                    Podemos compartir tus datos con terceros vendedores, proveedores de servicios, contratistas o agentes ("<strong>terceros</strong>") que prestan servicios para nosotros o en nuestro nombre y necesitan acceder a dicha información para realizar su trabajo.
                    Tenemos contratos en vigor con nuestros terceros, que están diseñados para ayudar a salvaguardar tu información personal.
                    Esto significa que no pueden hacer nada con tu información personal a menos que se lo hayamos ordenado.
                    Tampoco compartirán tu información personal con ninguna organización aparte de nosotros.
                    También se comprometen a proteger los datos que guardan en nuestro nombre y a conservarlos durante el periodo que les indiquemos.
                    Los terceros con los que podemos compartir información personal son los siguientes:
                </p>
                <ul>
                    <li>
                        <strong>Publicidad, Marketing directo y generación de contactos</strong>
                        <ul>
                            <li>Google AdsSense</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Programas de Marketing Afiliados</strong>
                        <ul>
                            <li>Amazon Afiliados</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Optimización de contenideos</strong>
                        <ul>
                            <li>YouTube video embed</li>
                            <li>Google Fonts</li>
                        </ul>
                    </li>
                </ul>
                <p>También es posible que tengamos que compartir tu información personal en las siguientes situaciones:</p>
                <ul>
                    <li>
                        <strong>Transferencias empresariales. </strong>
                        Podemos compartir o transferir tu información en relación con, o durante las negociaciones de, cualquier fusión, venta de activos de la empresa, financiación o adquisición de la totalidad o una parte de nuestro negocio a otra empresa.
                    </li>
                </ul>
                <h5 className="fw-semibold">Archivos de registro</h5>
                <p>Todo Lanzamientos sigue un procedimiento estándar de utilización de archivos de registro.
                    Estos archivos registran a los visitantes cuando visitan los sitios web.
                    Todas las empresas de hosting hacen esto y forma parte de las analíticas de los servicios de hosting.
                    La información recogida por los archivos de registro incluye direcciones de protocolo de Internet (IP), tipo de navegador, proveedor de servicios de Internet (ISP), sello de fecha y hora, páginas de referencia/salida y, posiblemente, el número de clics.
                    Estos datos no están vinculados a ninguna información que permita la identificación personal. El propósito de la información es analizar tendencias, administrar el sitio, rastrear los movimientos de los usuarios en el sitio web y recopilar información demográfica.
                </p>
                <h5 className="fw-semibold">Cookie DART de Google DoubleClick</h5>
                <p>Google es uno de los proveedores externos de nuestro sitio.
                    También utiliza cookies, conocidas como cookies DART, para ofrecer anuncios a los visitantes de nuestro sitio en función de su visita a Todo Lanzamientos y a otros sitios de Internet.
                    Sin embargo, los visitantes pueden optar por rechazar el uso de cookies DART, ya sea en el momento en el que el sitio web te lo pregunta o visitando la Política de privacidad de la red de anuncios y contenidos de Google en la siguiente URL: <a href="https://policies.google.com/technologies/ads" rel="noreferrer" target="_blank">https://policies.google.com/technologies/ads</a>.
                </p>
                <h5 className="fw-semibold">Aviso de afiliados</h5>
                <p>Todo Lanzamientos puede contener enlaces a sitios web afiliados, y recibimos una comisión de afiliación del programa Amazon Afiliados por cualquier compra que realices en el sitio web afiliado utilizando dichos enlaces.</p>
                <h5 className="fw-semibold">Información de menores de edad</h5>
                <p>Otra parte de nuestra prioridad es añadir protección para los niños mientras usan internet. Animamos a padres y tutores a que observen, participen y/o supervisen y guíen su actividad en línea.</p>
                <p>Todo Lanzamientos no recopila a sabiendas ningún tipo de Información de Identificación Personal de niños menores de 18 años. Si crees que tu hijo proporcionó este tipo de información en nuestro sitio web, te recomendamos encarecidamente que te pongas en contacto con nosotros de inmediato y haremos todo lo posible por eliminar rápidamente dicha información de nuestros registros.</p>
                <h5 className="fw-semibold">Cambios en la política de privacidad</h5>
                <p>Es posible que actualicemos nuestra Política de Privacidad de vez en cuando.
                    Por ello, te aconsejamos que revises periódicamente esta página para comprobar si se han producido cambios.
                    Te notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página. Estos cambios entrarán en vigor inmediatamente después de su publicación en esta página.</p>
                <h5>Contacto</h5>
                <p>Si tienes alguna pregunta o sugerencia sobre nuestra Política de privacidad, no dudes en ponerte en contacto con nosotros escribiendo a la dirección de correo {correo}.</p>
            </div>
        </div>
    )
}