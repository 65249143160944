import PlataformaType from "../types/PlataformaType";

export const plataformas: Array<PlataformaType> = [
    {
        idPlataforma: 1,
        nombre: 'PC',
        nombreCorto: 'PC',
        icono: 'pc',
        slug: 'pc'
    },
    {
        idPlataforma: 2,
        nombre: 'PlayStation 4',
        nombreCorto: 'PS4',
        icono: 'ps4',
        slug: 'ps4'
    },
    {
        idPlataforma: 3,
        nombre: "Xbox One",
        nombreCorto: "XONE",
        icono: "xboxone",
        slug: 'xbox-one'

    },
    {
        idPlataforma: 4,
        nombre: "Nintendo Switch",
        nombreCorto: "Switch",
        icono: "switch",
        slug: 'switch'
    },
    {
        idPlataforma: 5,
        nombre: "PlayStation 5",
        nombreCorto: "PS5",
        icono: "ps5",
        slug: "ps5"
    },
    {
        idPlataforma: 6,
        nombre: "Xbox Series X|S",
        nombreCorto: "XSX",
        icono: "xboxseries",
        slug: "xbox-series"
    },
]

export const plataformasOrdenadas = [...plataformas].sort((a, b) => a.nombre.toLowerCase().localeCompare(b.nombre.toLowerCase()));